import { Injectable, inject } from '@angular/core';
import { StoreService } from './store.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GetMediaEntityService {
  private storeService = inject(StoreService);


  private setImageUrlWithSize(data, path) {
    const imageFileName = this.sanitizerMetaUrl(data);
    const pathSizeSanitizer = this.sanitizerMetaImageSizeUrl(path);
    return pathSizeSanitizer + '/' + imageFileName;
  }

  public setDefaultImageUrl(data) {
    const imageFileName = this.sanitizerMetaUrl(data);
    return environment.beUrl + '/' + environment.imageDefaultPath + '/' + imageFileName;
  }

  private getDefaultImageUrl(data) {
    let defaultImageUrl = null;
    defaultImageUrl = this.setDefaultImageUrl(data);
    return defaultImageUrl;
  }

  private getImageUrlWithSize(data, style) {
    let imageUrlWithSize = null;
    const imagesStyleConfiguration = this.storeService.getConfigImagesStyle();
    if (data.meta.url) {
      if (imagesStyleConfiguration[style]) {
        const pathSize = imagesStyleConfiguration[style];
        imageUrlWithSize = this.setImageUrlWithSize(data.meta.url, pathSize);
      } else {
        imageUrlWithSize = this.getDefaultImageUrl(data.meta.url);
      }
    }
    return imageUrlWithSize;
  }

  public sanitizerMetaUrl(url) {
    return url.replace('public://', '');
  }

  private sanitizerMetaImageSizeUrl(url) {
    return url.split('?')[0];
  }

  public getFieldImageUrlbyMeta(data, style?): string {
    let imageUrl = null;
    if (data.meta.url) {
      if (style) {
        imageUrl = this.getImageUrlWithSize(data.meta.url, style);
      } else {
        imageUrl = this.getDefaultImageUrl(data.meta.url);
      }
    }
    return imageUrl;
  }

  public getFieldImageUrlbyUrl(url, style?): string {
    let imageUrl = null;
    if (style) {
      imageUrl = this.getImageUrlWithSize(url, style);
    } else {
      imageUrl = this.getDefaultImageUrl(url);
    }
    return imageUrl;
  }

}
