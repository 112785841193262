import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
// import { LocalizeRouterHttpLoader } from 'localize-router-http-loader';
// import { LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings } from 'localize-router';
import {LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings} from '@gilsdav/ngx-translate-router';
import {LocalizeRouterHttpLoader} from '@gilsdav/ngx-translate-router-http-loader';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ContentTypeResolver } from './core/guards/guards-service';
import { GuardsComponent } from './core/guards/guards.component';
import { PageDataResolver } from './core/resolvers/page-data.resolver';
import { WebsiteComponent } from './core/website/website.component';
import { WebsiteResolver } from './core/resolvers/website.resolver';

export function HttpLoaderFactory(translate: TranslateService, location: Location, settings: LocalizeRouterSettings, http: HttpClient) {
  return new LocalizeRouterHttpLoader(translate, location, settings, http);
}
const routeMatchList = [
  'must__editorial',
  'must__media',
  'must__offer',
  'must__school_offer',
  'must__press_kit',
  'notfound',
  'notavailable',
];

export function urlMatcher(url: UrlSegment[]) {
  if (!url.length) {
    return {consumed: url};
  }
  const match = url[0].path.match(/(must__editorial|must__media|must__offer|route__media|must__school_offer|must__press_kit|notfound|notavailable|search)/g);
  return !match || match.length === 0 ? ({consumed: url}) : null;
}

export const routes: Routes = [
  {
    path: '',
    component: WebsiteComponent,
    resolve: { websiteData: WebsiteResolver },
    children: [
      {
        matcher: urlMatcher,
        component: GuardsComponent,
        resolve: {contentType: ContentTypeResolver}
      },
      {
        path: 'must__editorial',
        loadChildren: () => import('./feature/editorial-page/editorial-page.module').then(m => m.EditorialPageModule),
        resolve: { currentPageData: PageDataResolver },
        data: { shouldReuse: false }
      },
      {
        path: 'must__media',
        loadChildren: () => import('./feature/media/media.module').then(m => m.MediaModule),
        resolve: { currentPageData: PageDataResolver },
        data: { shouldReuse: false }
      },
      {
        path: 'must__offer',
        loadChildren: () => import('./feature/offer/offer.module').then(m => m.OfferModule),
        resolve: { currentPageData: PageDataResolver },
        data: { shouldReuse: false }
      },
      {
        path: 'must__school_offer',
        loadChildren: () => import('./feature/school-offer/school-offer.module').then(m => m.SchoolOfferModule),
        resolve: { currentPageData: PageDataResolver },
        data: { shouldReuse: false }
      },
      {
        path: 'must__press_kit',
        loadChildren: () => import('./feature/press-kit/press-kit.module').then(m => m.PressKitModule),
        resolve: { currentPageData: PageDataResolver },
        data: { shouldReuse: false }
      },
      {
        path: 'notfound',
        loadChildren: () => import('./feature/not-found/not-found.module').then(m => m.NotFoundModule),
        data: { shouldReuse: false }
      },
      {
        path: 'notavailable',
        loadChildren: () => import('./feature/notavailable/notavailable.module').then(m => m.NotavailableModule),
        data: { shouldReuse: false }
      },
      {
        path: 'search',
        loadChildren: () => import('./feature/search/search.module').then(m => m.SearchModule),
        data: { shouldReuse: false }
      }
    ]
  }
];

@NgModule({
  imports: [
    FormsModule,
    NgSelectModule,
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload',
    enableTracing: false
}),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: HttpLoaderFactory,
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
      }
    }),
    FormsModule
  ],
  exports: [ RouterModule, LocalizeRouterModule ]
})
export class AppRoutingModule { }
