import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { alternateLanguageSubject$, StoreService } from '../services/store.service';
import { RouteDataService } from '../services/route-data.service';

@Injectable({
  providedIn: 'root'
})
export class WebsiteResolver  {
  private routeDataService = inject(RouteDataService);


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this.routeDataService.initAppStorage();
  }
}
