import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.qa';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }

  /**
   * The function search (by ID) the correct related content field in the included Array if exist
   * The function works with include of first level
   * @param {*} dataJson the json of jsonapi call
   * @param {*} field field that we need to search in the included Array
   * @returns {object} relatedContent, if related content exist return object. I not exist return null.
   * @memberof SharedService
   */
  public getRelatedContentPage(dataJson, field: string) {
    let relatedContent = null;
    if (dataJson?.included && dataJson.data.relationships[field] && dataJson.data.relationships[field].data) {
      const idField = dataJson.data.relationships[field].data.id;
      relatedContent = this.getRelatedContentById(dataJson, idField);
    }
    return relatedContent;
  }

  public getRelatedContentArrayPage(dataJson, field: string) {
    let relatedContentArray = null;
    if (dataJson.included && dataJson.data.relationships[field].data) {
      relatedContentArray = [];
      dataJson.data.relationships[field].data.forEach(element => {
        const idField = element.id;
        const relatedContent = this.getRelatedContentById(dataJson, idField);
        relatedContentArray.push(relatedContent);
      });
    }
    return relatedContentArray;
  }

  /**
   * The function search the correct related content field (in the included Array) of one included object.
   * IncludedObject can already have the included Array in the object or we can pass a separate Array of included
   * @param {object} IncludedObject
   * @param {string} field
   * @param {Array} [included]
   * @returns {Array}
   * @memberof SharedService
   */
  public getRelatedArraybyIncludedObject(IncludedObject, field: string, included?) : Array<object>{
    let relatedContentArray = null;
    if (!IncludedObject.included) {
      IncludedObject.included = (included) ? included : null;
    }
    if (IncludedObject.included && IncludedObject.relationships[field].data) {
      relatedContentArray = [];
      IncludedObject.relationships[field].data.forEach(element => {
        const idField = element.id;
        const relatedContent = this.getRelatedContentById(IncludedObject, idField);
        relatedContentArray.push(relatedContent);
      });
    }
    return relatedContentArray;
  }

  public getRelatedContentById(dataJson, id: string) {
    let relatedContent = null;
    const relatedContentArray = dataJson.included.filter(singleIncluded => singleIncluded.id === id);
    if (relatedContentArray.length > 0) {
      relatedContent = relatedContentArray[0];
    }
    return relatedContent;
  }

  private getFieldMediaImage(JsonEntity, dataJson) {
    let imageUrl = null;
    const idMediaData = dataJson.relationships.field_media_image.data.id;
    if (idMediaData) {
      const image = this.getRelatedContentById(JsonEntity, idMediaData);
      imageUrl = (image) ? environment.beUrl + image.attributes.uri.url : null;
    }
    return imageUrl;
  }

  public getMediaImagebyEntity(JsonEntity, field): string {
    let imageUrl = null;
    const relatedContent = this.getRelatedContentPage(JsonEntity, field);
    if (relatedContent) {
      imageUrl = this.getFieldMediaImage(JsonEntity, relatedContent);
    }
    return imageUrl;
  }

  /**
   * The function return the media url of one related content that is in included response of entity page
   * @param {object} fieldDataJson The object of related content get by included
   * @param {object} JsonEntity The json response of jsonapi with attributes and included
   * @param {string} [field]
   * @returns {string}
   * @memberof SharedService
   */
  public getMediaImageOfInclude(fieldDataJson, JsonEntity, field?): string {
    let imageUrl = null;
    const fieldToSearch = (field ? field : 'field_feat_image_media');
    if (fieldDataJson.relationships[fieldToSearch].data) {
      const id = fieldDataJson.relationships[fieldToSearch].data.id;
      const mediaData = this.getRelatedContentById(JsonEntity, id);
      if (mediaData) {
        imageUrl = this.getFieldMediaImage(JsonEntity, mediaData);
      }
    }
    return imageUrl;
  }

  public composeEntityPage(data, jsonapiIncluded) {
    return {
      included : jsonapiIncluded,
      data
    };
  }


}
