import { Component, OnInit, Input, inject } from '@angular/core';
import { GetConfigurationWebsiteService } from 'src/app/core/services/get-configuration-website.service';
import { StoreService } from 'src/app/core/services/store.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { SharedModule } from 'src/app/shared/shared.module';
import { MustLinkDirective } from 'src/app/directives/must-link.directive';
// import { LocalizeRouterService } from 'localize-router';

@Component({
  selector: 'app-opening-hours',
  templateUrl: './opening-hours.component.html',
  styleUrls: ['./opening-hours.component.scss'],
  standalone: true,
  imports: [SharedModule, MustLinkDirective]
})
export class OpeningHoursComponent implements OnInit {
  private getConfigurationWebsiteService = inject(GetConfigurationWebsiteService);
  private storeService = inject(StoreService);
  private localize = inject(LocalizeRouterService);

  @Input() model;
  openingHoursToday;
  gotTo;

  getTodayHours() {
    this.openingHoursToday = this.model.filter((day: any ) => day.isToday === true);
  }

  getLinkCalendarPage() {
    let calendarPage = null;
    const lang = this.localize.parser.currentLang;
    const siteStructure = this.storeService.getSiteStructure();
    const contactButton = this.getConfigurationWebsiteService.getButtonHours(siteStructure);
    if (contactButton) {
      contactButton.url = lang + '/' + contactButton.url;
      calendarPage = contactButton;
    }
    return calendarPage;
  }

  ngOnInit() {
    this.getTodayHours();
    this.gotTo = this.getLinkCalendarPage();
  }

}
