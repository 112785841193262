import { Component, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { StateActions } from '../../../app/actions/state.actions';
import { StorageMap } from '@ngx-pwa/local-storage';
import { get, values } from 'lodash';
import { FooterSocials } from './footersocials.model';
import { FooterTexts } from './footertexts.model';
import { FooterService } from '../services/footer.service';
import { menuDataSubject$, siteStructureSubject$ } from '../services/store.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { MustRouterLinkDirective } from 'src/app/directives/must-router-link.directive';
// import { AppModule } from 'src/app/app.module';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MustRouterLinkDirective, SharedModule]
})


export class FooterComponent implements OnInit {
  private actions = inject(StateActions);
  private footerService = inject(FooterService);
  protected stateStorage = inject(StorageMap);


  footerMenu: Array<object> = [];
  footerTexts: FooterTexts = {};
  footerSocials: FooterSocials = {};
  footerData: any = {};

  ngOnInit() {
    const siteStructure = siteStructureSubject$.value;
    this.footerData.newsletterUrl = get(siteStructure, 'data.attributes.field_goto_newsletter_subscribe.uri');
    this.footerData.addressHtml = get(siteStructure, 'data.attributes.field_info_address.processed');
    this.footerData.copyrigthHtml = get(siteStructure, 'data.attributes.field_info_legal_copyright.processed');
    this.footerData.infoLegalLinks = this.footerService.getFieldInfoLegalLinks(siteStructure);
    this.footerSocials = this.footerService.getFooterSocials();
    this.getFooterMenu();
  }

  getFooterMenu() {
    const menuData = menuDataSubject$.value;
    this.footerMenu = values(menuData['footer_menu']);
  }
}
