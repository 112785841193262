import { Component, Input, OnChanges, OnInit, SimpleChanges, inject } from '@angular/core';
import { MegaMenuService } from 'src/app/core/services/mega-menu.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
// import { LocalizeRouterService } from 'localize-router';
import { HeaderComponent } from '../../header.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MustRouterLinkDirective } from 'src/app/directives/must-router-link.directive';

@Component({
  selector: 'app-m-menu',
  templateUrl: './m-menu.component.html',
  styleUrls: ['./m-menu.component.scss'],
  standalone: true,
  imports: [SharedModule, MustRouterLinkDirective]
})
export class MMenuComponent implements OnInit, OnChanges {
  private megaMenuService = inject(MegaMenuService);
  translateservice = inject(TranslateService);
  private localize = inject(LocalizeRouterService);
  private headerComponent = inject(HeaderComponent);
  description: any;
  @Input() model;
  mMenu: any;

  ngOnInit() {
  }

  closeNavigation() {
    this.headerComponent.closeNavigation();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.model) {
      this.megaMenuService.getMegaMenu(this.model).then(
        (megaMenu: object) => {
          this.mMenu = megaMenu;
        }
      );
    }
  }

}
