import { Injectable, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { StoreService } from '../../services/store.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
// import { LocalizeRouterService } from 'localize-router';
import { SharedService } from '../../services/shared.service';
import { GetConfigurationWebsiteService } from '../../services/get-configuration-website.service';

@Injectable({
  providedIn: 'root'
})
export class GetConfigurationHeaderService {
  private storeService = inject(StoreService);
  private sharedService = inject(SharedService);
  private localize = inject(LocalizeRouterService);
  private getConfigurationWebsiteService = inject(GetConfigurationWebsiteService);


  public getLanguageWebsite() {
    const languages = Object.keys(environment.localeKey);
    Object.keys(languages).forEach((key) => languages[key] = languages[key]);
    const languagesArray = [];
    languages.forEach(element => {
      languagesArray.push({
        value: element,
        label: element + ' version'
      });
    });
    return languagesArray;
  }

  /**
   * This Method can works just with two language, because the switch language print in page just one alternative language
   *
   * @returns
   * @memberof GetConfigurationHeaderService
   */
  public getAlternativeLanguage() {
    let alternativeLanguage = null;
    const currentLanguage = this.getCurrentLanguage();
    const allLanguage = this.getLanguageWebsite();
    const alternativeLanguageData = allLanguage.filter( lang => lang.value !== currentLanguage);
    if (alternativeLanguageData) {
      alternativeLanguage = alternativeLanguageData[0];
    }
    return alternativeLanguage;
  }

  public getCurrentLanguage() {
    const currentLanguages = this.localize.parser.currentLang;
    return currentLanguages;
  }

  public getMenuData(mainMenu) {
    const mainMenuData = Object.keys(mainMenu).map(item => mainMenu[item]);
    mainMenuData.forEach(element => {
      if (element.children) {
        const children = Object.keys(element.children).map(child => element.children[child]);
        element.children = _.orderBy(children, ['weight'], ['asc']);
        // element.children.forEach(subChild => {
        //   if (subChild.children) {
        //     const subchildren = Object.keys(subChild.children).map(schild => subChild.children[schild]);
        //     subChild.children = _.orderBy(subchildren, ['weight'], ['asc']);
        //   }
        // });
      }
    });
    return _.orderBy(mainMenuData, ['weight'], ['asc']);
  }

  public getButtonStore() {
    let buttonStore: object;
    const siteStructure = this.storeService.getSiteStructure();
    if (siteStructure?.data) {
      const buttonStoreData = siteStructure?.data.attributes.field_goto_store;
      if (buttonStoreData) {
        buttonStore = {
          label: buttonStoreData.title,
          url: buttonStoreData.uri
        };
      }
    }
    return buttonStore;
  }

  public setLogo() {
    return this.storeService.getSiteLogo();
  }

  public getItemsBottomNavigation() {
    const itemsBottomNavigation = {};
    const siteStructure = this.storeService.getSiteStructure();
    const lang = this.localize.parser.currentLang;
    if (siteStructure) {
      const infoButton = {url : lang + '/' + this.getConfigurationWebsiteService.getButtonInfo(siteStructure).url};
      if (infoButton) {
        itemsBottomNavigation['infoButton'] = infoButton;
      }
      const ticketButton = {url : lang + '/' + this.getConfigurationWebsiteService.getButtonTicket(siteStructure).url};
      if (ticketButton) {
        itemsBottomNavigation['ticketButton'] = ticketButton;
      }
      const calendarButton = {url : lang + '/' + this.getConfigurationWebsiteService.getButtonCalendar(siteStructure).url};
      if (calendarButton) {
        itemsBottomNavigation['calendarButton'] = calendarButton;
      }
      const contactButton = {url : lang + '/' + this.getConfigurationWebsiteService.getButtonContact(siteStructure).url};
      if (contactButton) {
        itemsBottomNavigation['contactButton'] = contactButton;
      }
    }
    return itemsBottomNavigation;
  }

  public getLogoMobile() {
    const siteStructure = this.storeService.getSiteStructure();
    let logoMobile;
    if (siteStructure && siteStructure['data'].relationships.field_fe_logo_alt.data) {
        const logoMobileData = this.sharedService.getRelatedContentPage(siteStructure, 'field_fe_logo_alt');
        if (logoMobileData) {
          logoMobile = environment.hostName + logoMobileData.attributes.uri.url;
        }
    }
    return logoMobile;
  }
}
