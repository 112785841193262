import { Component, OnInit, inject } from '@angular/core';
import { StoreService } from '../services/store.service';
import { HeaderComponent } from '../header/header.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from '../footer/footer.component';

@Component({
  selector: 'app-website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.scss'],
  standalone: true,
  imports: [HeaderComponent, RouterModule, FooterComponent]
})
export class WebsiteComponent implements OnInit {
  private storeService = inject(StoreService);

  externalCss;

  ngOnInit() {
    const siteStructure = this.storeService.getSiteStructure();
    if (siteStructure.data.attributes.field_additional_css) {
      this.externalCss = siteStructure.data.attributes.field_additional_css.uri;
      const head = document.getElementsByTagName('head')[0];
      const style = document.createElement('link');
      style.rel = 'stylesheet';
      style.href = this.externalCss;
      head.appendChild(style);
    }
  }

}
