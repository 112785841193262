import { NgModule } from '@angular/core';
import { CommonModule} from '@angular/common';
// import { BlockCardComponent } from './modules/block-card/block-card.component';
// import { ExternalLinkComponent } from './blocks/external-link/external-link.component';
// import { LongMagazineLinkComponent } from './blocks/long-magazine-link/long-magazine-link.component';
// import { LongTextImageComponent } from './blocks/long-text-image/long-text-image.component';
// import { PressReleaseComponent } from './blocks/press-release/press-release.component';
// import { QuoteComponent } from './blocks/quote/quote.component';
// import { RepeatArticleLinkComponent } from './blocks/repeat-article-link/repeat-article-link.component';
// import { RepeatNumbersComponent } from './blocks/repeat-numbers/repeat-numbers.component';
// import { TextAndImageComponent } from './blocks/text-and-image/text-and-image.component';
// import { TextButtonComponent } from './blocks/text-button/text-button.component';
// import { HomePageTitleComponent } from './modules/home-page-title/home-page-title.component';
// import { ModuleCarouselComponent } from './modules/module-carousel/module-carousel.component';
// import { ModuleCarouselImagesComponent } from './modules/module-carousel-images/module-carousel-images.component';
// import { ProjectCarouselNavigationComponent } from './modules/project-carousel-navigation/project-carousel-navigation.component';
// import { ProjectHeaderComponent } from './blocks/project-header/project-header.component';
// import { ProjectIntroComponent } from './blocks/project-intro/project-intro.component';
// import { MultiPurposeComponent } from './modules/multi-purpose/multi-purpose.component';
import { RouterModule } from '@angular/router';
// import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { DynamicModule } from 'ng-dynamic-component';
// import { ModuleHeaderComponent } from './modules/module-header/module-header.component';
import { TranslateModule } from '@ngx-translate/core';
// import { ModuleTextComponent } from './modules/module-text/module-text.component';
// import { ModuleAttachmentComponent } from './modules/module-attachment/module-attachment.component';
// import { BlockAttachmentComponent } from './blocks/block-attachment/block-attachment.component';
// import { ModuleBackComponent } from './modules/module-back/module-back.component';
// import { ModuleVideoComponent } from './modules/module-video/module-video.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BasicAuthInterceptor } from '../auth/basicauth.interceptor';
// import { BlockPreviewInnerTextComponent } from './blocks/block-preview-inner-text/block-preview-inner-text.component';
// import { BlockPreviewOuterTextComponent } from './blocks/block-preview-outer-text/block-preview-outer-text.component';
// import { BlockPreviewCardComponent } from './blocks/block-preview-card/block-preview-card.component';
// import { BlockSliderTextBoxesComponent } from './blocks/block-slider-text-boxes/block-slider-text-boxes.component';
// import { ModuleMultipurposeComponent } from './modules/module-multipurpose/module-multipurpose.component';
// import { BlockSliderBoxesComponent } from './blocks/block-slider-boxes/block-slider-boxes.component';
// import { ModuleSliderComponent } from './modules/module-slider/module-slider.component';
// import { BlockTitleLineComponent } from './blocks/block-title-line/block-title-line.component';
// import { ModuleSingleImageComponent } from './modules/module-single-image/module-single-image.component';
// import { BlockSingleImageComponent } from './blocks/block-single-image/block-single-image.component';
// import { ContentModuleComponent } from './modules/content-module/content-module.component';
// import { ModuleTextImageTwoColsComponent } from './modules/module-text-image-two-cols/module-text-image-two-cols.component';
// import { ModuleHomepageTitleComponent } from './modules/module-homepage-title/module-homepage-title.component';
// import { ModuleViewModuleComponent } from './modules/module-view-module/module-view-module.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
// import { ProjectUnstructuredComponent } from './blocks/project-unstructured/project-unstructured.component';
import { NgxMasonryModule } from 'ngx-masonry';
// import { ModuleTopicMasonryComponent } from './modules/module-topic-masonry/module-topic-masonry.component';
// import { BlockIframeComponent } from './blocks/block-iframe/block-iframe.component';
// import { ModuleTabComponent } from './modules/module-tab/module-tab.component';
// import { ModuleQuoteComponent } from './modules/module-quote/module-quote.component';
// import { BlockInfoBoxesComponent } from './blocks/block-info-boxes/block-info-boxes.component';
// import { ModuleInfographicModuleComponent } from './modules/module-infographic-module/module-infographic-module.component';
// import { BlockPreviewInnerTextIconComponent } from './blocks/block-preview-inner-text-icon/block-preview-inner-text-icon.component';
import { InViewportModule } from 'ng-in-viewport';
// import { Ng10OdometerModule } from 'ng10-odometer';
// import { ModuleOnlyTextAndLinkComponent } from './modules/module-only-text-and-link/module-only-text-and-link.component';
import { LightboxModule } from 'ngx-lightbox';
// import { BlockTableComponent } from './blocks/block-table/block-table.component';
// import { ModuleKeyFactComponent } from './modules/module-key-fact/module-key-fact.component';
// import { BlockPreviewSimpleCardComponent } from './blocks/block-preview-simple-card/block-preview-simple-card.component';
// import { ModuleAccordionComponent } from './modules/module-accordion/module-accordion.component';
// import { ModuleThematicBlocksComponent } from './modules/module-thematic-blocks/module-thematic-blocks.component';
// import { BlockPreviewEventComponent } from './blocks/block-preview-event/block-preview-event.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { BlockDetailComponent } from './blocks/block-detail/block-detail.component';
// import { ModuleTitleComponent } from './modules/module-title/module-title.component';
// import { ModuleDetailsComponent } from './modules/module-details/module-details.component';
// import { BlockAlertComponent } from './blocks/block-alert/block-alert.component';
// import { ModuleAlertComponent } from './modules/module-alert/module-alert.component';
// import { BlockCtaComponent } from './blocks/block-cta/block-cta.component';
// import { MustLinkDirective } from '../directives/must-link.directive';
// import { ModuleCtaComponent } from './modules/module-cta/module-cta.component';
// import { ModuleSpacerComponent } from './modules/module-spacer/module-spacer.component';
// import { ModuleHomeHeroComponent } from './modules/module-home-hero/module-home-hero.component';
// import { ModuleSliderBlocksComponent } from './modules/module-slider-blocks/module-slider-blocks.component';
// import { BlockImgTextColumnComponent } from './blocks/block-img-text-column/block-img-text-column.component';
// import { BlockImgTextRowComponent } from './blocks/block-img-text-row/block-img-text-row.component';
// import { BlockImgAutoHeightTextColumnComponent } from './blocks/block-img-auto-height-text-column/block-img-auto-height-text-column.component';
// import { BlockPreviewSwitchComponent } from './blocks/block-preview-switch/block-preview-switch.component';
// import { ModuleTextDetailsComponent } from '../feature/offer/components/module-text-details/module-text-details.component';
// import { ModuleTitleDetailsComponent } from '../feature/offer/components/module-title-details/module-title-details.component';
// import { ModuleMasonryPreviewBlocksComponent } from './modules/module-masonry-preview-blocks/module-masonry-preview-blocks.component';
// import { ModuleRowPreviewBlocksComponent } from './modules/module-row-preview-blocks/module-row-preview-blocks.component';
// import { BlockTextImgColumnComponent } from './blocks/block-text-img-column/block-text-img-column.component';
// import { ViewComponent } from '../feature/view/view.component';
// import { MustRouterLinkDirective } from '../directives/must-router-link.directive';
// import { BlockDocumentsAndLinksComponent } from './blocks/block-documents-and-links/block-documents-and-links.component';
// import { ModuleCalendarComponent } from './modules/module-calendar/module-calendar.component';
// import { BlockCalendarComponent } from './blocks/block-calendar/block-calendar.component';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { CalendarDateFormatter, CalendarModule, CalendarMomentDateFormatter, DateAdapter, MOMENT } from 'angular-calendar';
// import { BreadcrumbComponent } from './modules/module-title/breadcrumb/breadcrumb.component';
// import { BlockOpeningHourComponent } from './blocks/block-opening-hour/block-opening-hour.component';
// import { SearchPanelComponent } from '../core/header/components/search-panel/search-panel.component';
// import { SafeHtmlPipe } from '../core/pipes/safehtml.pipe';
// import { BrowserModule } from '@angular/platform-browser';

export function momentAdapterFactory() {
  return adapterFactory(moment);
}



@NgModule({

  imports: [
  CommonModule,
    RouterModule,
   // SlickCarouselModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    InViewportModule,
    NgxPaginationModule,
    CarouselModule,
    NgxMasonryModule,
    LightboxModule,
    FontAwesomeModule,
   // Ng10OdometerModule.forRoot(),
    DynamicModule/* .withComponents([
        ExternalLinkComponent
     ])*/,
     TranslateModule,
    CalendarModule.forRoot(
      {
        provide: DateAdapter,
        useFactory: momentAdapterFactory
      },
      {
        dateFormatter: {
          provide: CalendarDateFormatter,
          useClass: CalendarMomentDateFormatter
        }
      }
    ),
  ],
  declarations: [
   // ModuleTextComponent,
   // BlockCardComponent,
   // ExternalLinkComponent,
   // LongMagazineLinkComponent,
   // LongTextImageComponent,
   // PressReleaseComponent,
   // QuoteComponent,
   // RepeatArticleLinkComponent,
   // ModuleAttachmentComponent,
   // RepeatNumbersComponent,
   // TextAndImageComponent,
   // TextButtonComponent,
   // HomePageTitleComponent,
   // ModuleCarouselComponent,
   // ModuleCarouselImagesComponent,
   // ProjectCarouselNavigationComponent,
   // ProjectHeaderComponent,
   // ProjectIntroComponent,
   // MultiPurposeComponent,
   // ModuleHeaderComponent,
   // BlockAttachmentComponent,
   // ModuleBackComponent,
   // ModuleVideoComponent,
   // BlockPreviewInnerTextComponent,
   // BlockPreviewOuterTextComponent,
   // BlockSliderTextBoxesComponent,
   // BlockPreviewCardComponent,
   // ModuleMultipurposeComponent,
   // ModuleSliderComponent,
   // BlockTitleLineComponent,
   // ModuleMultipurposeComponent,
   // BlockSliderBoxesComponent,
   // ModuleSingleImageComponent,
   // BlockSingleImageComponent,
   // ContentModuleComponent,
   // ModuleTextImageTwoColsComponent,
   // ModuleHomepageTitleComponent,
   // ModuleViewModuleComponent,
   // ProjectUnstructuredComponent,
   // ModuleTopicMasonryComponent,
   // BlockIframeComponent,
   // ModuleTabComponent,
   // ModuleQuoteComponent,
   // BlockInfoBoxesComponent,
   // ModuleInfographicModuleComponent,
   // BlockPreviewInnerTextIconComponent,
   // ModuleOnlyTextAndLinkComponent,
   // BlockTableComponent,
   // ModuleKeyFactComponent,
   // ModuleAccordionComponent,
   // BlockPreviewSimpleCardComponent,
   // ModuleThematicBlocksComponent,
   // BlockPreviewEventComponent,
   // BlockDetailComponent,
   // ModuleTitleComponent,
   // ModuleDetailsComponent,
   // BlockAlertComponent,
   // ModuleAlertComponent,
   // BlockCtaComponent,
   // MustLinkDirective,
   // ModuleCtaComponent,
   // ModuleSpacerComponent,
   // ModuleHomeHeroComponent,
   // ModuleSliderBlocksComponent,
   // BlockImgTextColumnComponent,
   // BlockImgTextRowComponent,
   // BlockImgAutoHeightTextColumnComponent,
   // BlockPreviewSwitchComponent,
   // ModuleTextDetailsComponent,
   // ModuleTitleDetailsComponent,
   // ModuleMasonryPreviewBlocksComponent,
   // ModuleRowPreviewBlocksComponent,
   // BlockTextImgColumnComponent,
   // ViewComponent,
   // MustRouterLinkDirective,
   // ModuleCalendarComponent,
   // BlockCalendarComponent,
   // BlockDocumentsAndLinksComponent,
   // BreadcrumbComponent,
   // BlockOpeningHourComponent,
   // SearchPanelComponent,
   // SafeHtmlPipe
  ],
  exports: [
    CommonModule,
    TranslateModule,
    CalendarModule,
    FormsModule,
    RouterModule
   // ModuleTextComponent,
   // BlockCardComponent,
   // ExternalLinkComponent,
   // LongMagazineLinkComponent,
   // LongTextImageComponent,
   // PressReleaseComponent,
   // QuoteComponent,
   // RepeatArticleLinkComponent,
   // ModuleAttachmentComponent,
   // RepeatNumbersComponent,
   // TextAndImageComponent,
   // TextButtonComponent,
   // HomePageTitleComponent,
   // ModuleCarouselComponent,
   // ProjectCarouselNavigationComponent,
   // ProjectHeaderComponent,
   // ProjectIntroComponent,
   // MultiPurposeComponent,
   // ModuleHeaderComponent,
   // ModuleBackComponent,
   // ModuleVideoComponent,
   // BlockPreviewInnerTextComponent,
   // BlockPreviewOuterTextComponent,
   // BlockSliderTextBoxesComponent,
   // BlockPreviewCardComponent,
   // ModuleMultipurposeComponent,
   // ModuleSliderComponent,
   // ModuleMultipurposeComponent,
   // BlockSliderBoxesComponent,
   // ModuleSingleImageComponent,
   // BlockSingleImageComponent,
   // ContentModuleComponent,
   // ModuleTextImageTwoColsComponent,
   // ModuleHomepageTitleComponent,
   // ModuleViewModuleComponent,
   // ModuleTopicMasonryComponent,
   // BlockIframeComponent,
   // ModuleTabComponent,
   // ModuleQuoteComponent,
   // BlockInfoBoxesComponent,
   // ModuleInfographicModuleComponent,
   // BlockPreviewInnerTextIconComponent,
   // ModuleOnlyTextAndLinkComponent,
   // BlockTableComponent,
   // ModuleKeyFactComponent,
   // BlockPreviewSimpleCardComponent,
   // ModuleAccordionComponent,
   // ModuleThematicBlocksComponent,
   // BlockPreviewEventComponent,
   // FontAwesomeModule,
   // BlockDetailComponent,
   // ModuleTitleComponent,
   // ModuleDetailsComponent,
   // BlockAlertComponent,
   // ModuleAlertComponent,
   // MustLinkDirective,
   // BlockCtaComponent,
   // ModuleCtaComponent,
   // ModuleSpacerComponent,
   // ModuleHomeHeroComponent,
   // ModuleCarouselImagesComponent,
   // ModuleSliderBlocksComponent,
   // BlockImgTextColumnComponent,
   // BlockImgTextRowComponent,
   // BlockImgAutoHeightTextColumnComponent,
   // BlockPreviewSwitchComponent,
   // ModuleTextDetailsComponent,
   // ModuleTitleDetailsComponent,
   // NgxMasonryModule,
   // ModuleMasonryPreviewBlocksComponent,
   // ModuleRowPreviewBlocksComponent,
   // BlockTextImgColumnComponent,
   // ViewComponent,
   // MustRouterLinkDirective,
   // BlockCalendarComponent,
   // ModuleCalendarComponent,
   // BlockDocumentsAndLinksComponent,
   // BreadcrumbComponent,
   // BlockOpeningHourComponent,
   // SearchPanelComponent,
   // SafeHtmlPipe
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BasicAuthInterceptor,
      multi: true
    },
    {
      provide: MOMENT,
      useValue: moment
    },

  ]
})
export class SharedModule { }
