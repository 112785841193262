import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpService } from './httpErrorHandler.service';
import { Observable } from 'rxjs';
import { AppErrorHandler } from '../../../app/shared/models/error.handler';
import { catchError } from 'rxjs/operators';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { StateActions } from 'src/app/actions/state.actions';
import { StoreService } from './store.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  httpService = inject(HttpService);
  httpClient = inject(HttpClient);
  private translateService = inject(TranslateService);
  private actions = inject(StateActions);
  private storeService = inject(StoreService);


  /**
   *
   * The first endpoint with the uuid of country (by environment).
   * @returns {(Observable<any | AppErrorHandler>)} the response of country endpoint
   * @memberof MenuService
   */
  getSiteStructure(): Observable<any | AppErrorHandler> {
    return this.httpClient.get<any>(environment.apiURL + environment.localeKeyPrefix[this.translateService.currentLang] + '/' +
      environment.siteStructure +
      environment.localeKey[this.translateService.currentLang])
      .pipe(catchError(err => this.httpService.handleError(err)));
  }

  getRouteData(route: string): Observable<any | AppErrorHandler> {
    const mainMenu = this.storeService.getMainMenu();
    const routeObject = _.filter(mainMenu, (item: any) => item.slug === route);
    // if the route exist in the hidden menu
    if (routeObject && routeObject.length > 0) {
      // tslint:disable-next-line: max-line-length
      return this.httpClient.get<any>(environment.apiURL + environment.localeKeyPrefix[this.translateService.currentLang] + '/' + routeObject[0]['parameters'])
        .pipe(catchError(err => this.httpService.handleError(err)));
    }
  }

  /**
   *
   * endpoint return all the country in the BE
   * @returns {(Observable<any | AppErrorHandler>)}
   * @memberof MenuService
   */
  getCountries(): Observable<any | AppErrorHandler> {
    return this.httpClient.get<any>(environment.apiURL + '/jsonapi/country_site/country')
      .pipe(catchError(err => this.httpService.handleError(err)));
  }

  getCurrentLocaleKey() {
    return environment.localeKey[this.translateService.currentLang];
  }
}
