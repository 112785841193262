import { Injectable, inject } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
// import { LocalizeRouterService } from 'localize-router';
import { alternateLanguageSubject$ } from '../../services/store.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationHeaderService {
  private localize = inject(LocalizeRouterService);


  public langSwitch(lang) {
    // @TODO navigate to alternate lang route
    const alternateLanguages = alternateLanguageSubject$.value;
    if (alternateLanguages[lang]) {
        // Window.location.href is used to force indexdb rebuild
        window.location.href = lang + '/' + alternateLanguages[lang];
    }
  }

   // @todo move in service
  /**
   *
   * @private
   * @param {*} itemObject
   * @memberof HeaderComponent
   */
  private setActiveItem(itemObject) {
    itemObject.actualRoute = true;
  }

  // @todo move in service
  /**
   * If one child item has same slug of route return the parent
   * @private
   * @param {string} actualRoute
   * @returns {Object} parentItem
   * @memberof HeaderComponent
   */
  private getParentActiveItem(mainMenu, actualRoute: string) {
    let parentItem;
    mainMenu.find(
      item => {
        if (item.children) {
          item.children.find(child => {
            if (child['field_route'] === actualRoute) {
              parentItem = item;
            }
          }
          );
        }
      }
    );
    return parentItem;
  }

  // @todo move in service
  /**
   * Check url route. If one item of menu has same slug became active.
   * If one child item has same slug of route the parent became active.
   * @private
   * @memberof HeaderComponent
   */
  public getActualRoute(mainMenu) {
    mainMenu.forEach(element => {
      element.active = false;
    });
    const lang = this.localize.parser.currentLang;
    const route = window.location.pathname.replace('/' + lang + '/', '');
    if (route && mainMenu) {
      const ItemActual = mainMenu.find(item => item['full-slug'] === route);
      const oldActual = mainMenu.find(item => item['actualRoute']);
      if (oldActual) {
        oldActual.actualRoute = false;
      }
      // Check If one item of menu has same slug
      if (ItemActual) {
        this.setActiveItem(ItemActual);
      } else {
        // Check If one child item of menu has same slug
        const ParentActiveItem = this.getParentActiveItem(mainMenu, route);
        if (ParentActiveItem) {
          this.setActiveItem(ParentActiveItem);
        }
      }
    }
    return mainMenu;
  }
}
