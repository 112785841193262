import { Directive, ElementRef, HostListener, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[appMustRouterLink]',
  standalone: true
})
export class MustRouterLinkDirective {
  private router = inject(Router);
  private translateService = inject(TranslateService);
  private elRef = inject(ElementRef);


  @HostListener('click', ['$event'])
  clicked(event: Event) {
    event.preventDefault();
    const url = this.translateService.currentLang + '/' + this.elRef.nativeElement.getAttribute('href');
    this.router.navigateByUrl(url);
    return;
  }

}
