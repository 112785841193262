import { Component, EventEmitter, Input, OnInit, Output, AfterViewChecked, ViewChild, ElementRef, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { siteStructureSubject$ } from '../../../services/store.service';
import { SharedService } from '../../../services/shared.service';
import { MustLinkMapper } from '../../../services/must-link.service';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
    selector: 'app-search-panel',
    templateUrl: './search-panel.component.html',
    styleUrls: ['./search-panel.component.scss'],
    standalone: true,
    imports: [SharedModule]
})
export class SearchPanelComponent implements OnInit, AfterViewChecked {
    private router = inject(Router);
    private translateService = inject(TranslateService);
    private sharedService = inject(SharedService);

    @Input() model;
    @Output() closeSearch = new EventEmitter<boolean>();
    @ViewChild('searchInput') searchElement: ElementRef;
    query: string;
    ctaModel: any;
    error: boolean;

    ngOnInit() {
      const siteStructure = siteStructureSubject$.value;
      const routeEducation = this.sharedService.getRelatedContentPage(siteStructure, 'field_school_page');
      this.ctaModel = MustLinkMapper.parseRelatedContent(routeEducation);
    }

    ngAfterViewChecked() {
        if (this.model.show) {
            this.searchElement.nativeElement.focus();

        }
    }

    doSearch() {
      if (this.query && this.query.length > 3) {
        const url = '/' + this.translateService.currentLang + '/search?q=' + this.query;
        this.router.navigateByUrl(url, {});
        this.error = false;
        this.setClose();
      } else {
        this.error = true;
      }
    }

    setClose() {
       this.closeSearch.emit(true);
    }
}
