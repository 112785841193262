import { provideZoneChangeDetection, TransferState, inject } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_ID, NgModule } from '@angular/core';
// import { NgxdModule } from '@ngxd/core';
import { DynamicModule } from 'ng-dynamic-component';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
// import { PapaParseModule } from 'ngx-papaparse';
import { NgRedux, NgReduxModule } from '@angular-redux2/store';
import { IAppState, INITIAL_STATE, rootReducer } from './app.store';
// import tranlsate settings
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';

import { HttpService } from './core/services/httpErrorHandler.service';
import { AddHeaderInterceptorService } from './core/services/add-header-interceptor.service';

// import { AngularFontAwesomeModule } from 'angular-font-awesome';

import { StateActions } from './actions/state.actions';
import { TranslateBrowserLoader } from './core/services/translate-browser-loader.service';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { BasicAuthInterceptor } from './auth/basicauth.interceptor';
// const { localStorageProviders } =require('@ngx-pwa/local-storage');
import { environment } from 'src/environments/environment';
import { MMenuComponent } from './core/header/components/m-menu/m-menu.component';
import { TopHeaderComponent } from './core/header/components/top-header/top-header.component';
import { SearchPanelComponent } from './core/header/components/search-panel/search-panel.component';
import { ModuleSocialSidebarComponent } from './core/footer/module-social-sidebar/module-social-sidebar.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { InViewportModule } from 'ng-in-viewport';
// import { Ng2OdometerModule } from 'ng2-odometer';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomRouteReuseStategy } from './app-route-reuse-strategy';
import { LightboxEvent, LightboxWindowRef } from 'ngx-lightbox/lightbox-event.service';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';
import { SharedModule } from './shared/shared.module';
import { GuardsComponent } from './core/guards/guards.component';
import { ContentTypeResolver } from './core/guards/guards-service';
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule, NgxUiLoaderRouterModule } from 'ngx-ui-loader';
import { MobileHeaderComponent } from './core/header/components/mobile-header/mobile-header.component';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { WebsiteComponent } from './core/website/website.component';
import { OpeningHoursComponent } from './core/header/components/opening-hours/opening-hours.component';
import { StorageModule } from '@ngx-pwa/local-storage';
// import { Ng10OdometerModule } from 'ng10-odometer';
import { BrowserModule } from '@angular/platform-browser';

registerLocaleData(localeIt, 'it');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/locales/', '.json');
}
export function exportTranslateStaticLoader(http: HttpClient, transferState: TransferState) {
  return new TranslateBrowserLoader('/assets/i18n/', '.json', transferState, http);
}

const numberRandomGif = Math.floor(Math.random() * 1) + 1;

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#ffd93c',
  bgsOpacity: 1,
  bgsPosition: 'bottom-right',
  bgsSize: 60,
  bgsType: 'ball-spin-clockwise',
  blur: 0,
  delay: 0,
  fgsColor: 'rgba(255,255,255,0)',
  fgsPosition: 'center-center',
  fgsSize: 20,
  fgsType: 'ball-spin-clockwise',
  gap: 0,
  logoPosition: 'center-center',
  logoSize: 120,
  logoUrl: '/assets/images/loader_1.gif',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  // overlayColor: '#fff',
  overlayColor: 'rgba(255, 255, 255, 0.7)',
  pbColor: '#ffd93c',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: true,
  text: '',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 500
};

@NgModule({ declarations: [

        ModuleSocialSidebarComponent,
        GuardsComponent,

    ],
    exports: [
        FontAwesomeModule,
        GuardsComponent
    ],
    bootstrap: [],
    imports: [
     /* AppComponent,
        FooterComponent,
        HeaderComponent,
        MMenuComponent,
        TopHeaderComponent,
        MobileHeaderComponent,
        WebsiteComponent,
        OpeningHoursComponent,*/
        StorageModule.forRoot({
            LSPrefix: 'Must_' + environment.name + "_", // Note the underscore
            IDBDBName: 'Must_' + environment.name + '_ngStorage'
        }),
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        InViewportModule,
        AppRoutingModule,
        // NgxdModule,
        DynamicModule /* .withComponents([])*/,
        // AngularFontAwesomeModule,
        NgReduxModule,
       // Ng10OdometerModule.forRoot(),
        TranslateModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        // BrowserModule.withServerTransition({ appId: 'Must' }),
        FontAwesomeModule,
        // PapaParseModule,
        SharedModule,
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
        NgxUiLoaderRouterModule,
        // NgxUiLoaderHttpModule.forRoot({ exclude: [], showForeground: true }),
        NgbModule], providers: [
          provideZoneChangeDetection({ignoreChangesOutsideZone: true}),
        {
            provide: APP_ID,
            useValue: "Must",
        },
        CustomRouteReuseStategy,
        HttpService,
        StateActions,
        Lightbox,
        LightboxWindowRef,
        LightboxConfig,
        LightboxEvent,
        // localStorageProviders({ prefix: 'Must_' + environment.name }),
        { provide: HTTP_INTERCEPTORS, useClass: AddHeaderInterceptorService, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BasicAuthInterceptor,
            multi: true
        },
        ContentTypeResolver,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
  constructor() {
    const ngRedux = inject<NgRedux<IAppState>>(NgRedux);

    ngRedux.configureStore(rootReducer, INITIAL_STATE);
  }
}

TranslateModule.forChild({
  loader: {
    provide: TranslateLoader,
    useFactory: exportTranslateStaticLoader,
    deps: [HttpClient, TransferState]
  }
});
