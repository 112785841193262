import { Injectable, inject } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { forkJoin } from 'rxjs';
import { PreviewSimpleCard } from 'src/app/shared/blocks/block-preview-simple-card/model-preview-simple-card';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
// import { LocalizeRouterService } from 'localize-router';

@Injectable({
  providedIn: 'root'
})
export class MegaMenuService {
  private localize = inject(LocalizeRouterService);


  public preview_simple_card(data) {
    return new Promise((resolve, reject) => {
      const slideBlockSimpleCard: PreviewSimpleCard = {
        title: data.title,
        image: environment.beUrl + data.image,
        url: this.localize.parser.currentLang + '/' + data.fe_route,
        target_blank: false,
      };
      resolve(slideBlockSimpleCard);
    });
  }

  public getMegaMenu(data) {
    return new Promise((resolve, reject) => {
      const promiseArray = [];
      let mMenu = {};
      if (data.mega_menu) {
        const blocks = Object.keys(data.mega_menu.editorial_push).map(block => data.mega_menu.editorial_push[block]);
        blocks.forEach(singleBlock => {
          promiseArray.push(this.preview_simple_card(singleBlock));
        });
        forkJoin(promiseArray).subscribe(
          (previewBlocks: Array<object>) => {
            mMenu['blockSwitch'] = previewBlocks;
          }
        );
      } else {
        mMenu['blockSwitch'] = null;
      }

      mMenu = {
        title: (data.mega_menu) ? data.mega_menu.title : data['content-title'],
        description: (data.mega_menu) ? data.mega_menu.description : null,
        // tslint:disable-next-line: max-line-length
        // blocks : (this.model.mega_menu) ? Object.keys(this.model.mega_menu.editorial_push).map( block => this.model.mega_menu.editorial_push[block]) : null
      };
      resolve(mMenu);
    });
  }

}
